import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { catchError, map, mergeMap, Observable, of, tap, withLatestFrom } from 'rxjs';

import { AppState } from '@store/app.state';
import { selectRouteParams } from '@store/router';
import { companiesAction, selectCompanies } from '@store/user';

import { getCompanyAndContractAtIndex } from '@utils/company-helpers/get-company-and-contract-at-index';
import { getCompanyByContractId } from '@utils/company-helpers/get-company-by-contract-id';

import { activeCompanyAction, defaultCompanyAction } from './preferences.actions';

@Injectable()
export class UserPreferencesEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store<AppState>) {}

  public readonly setDefaultCompany$ = createEffect(() => this.setDefaultCompany());

  public readonly setActiveCompany$ = createEffect(() => this.setActiveCompany());

  public readonly setDefaultActiveCompany$ = createEffect(() => this.setDefaultActiveCompany());

  private defaultContractID: string = '';

  private setDefaultCompany(): Observable<Action> {
    return this.actions$.pipe(
      ofType(companiesAction.success),
      map(({ companies }) => getCompanyAndContractAtIndex(companies, 0)),
      tap((defaultCompany) => (this.defaultContractID = defaultCompany.contracts[0].sid)),
      map((defaultCompany) =>
        defaultCompany
          ? defaultCompanyAction.success({ defaultCompany })
          : defaultCompanyAction.failed()
      )
    );
  }

  private setActiveCompany(): Observable<Action> {
    return this.actions$.pipe(
      ofType(companiesAction.success),
      withLatestFrom(this.store.select(selectRouteParams)),
      mergeMap(([actionData, params]) =>
        of(actionData?.newSid || params?.['id'] || this.defaultContractID)
      ),
      mergeMap((id) =>
        this.store.select(selectCompanies).pipe(
          map((companies) => getCompanyByContractId(companies, id)),
          catchError(() => {
            this.store.dispatch(activeCompanyAction.failed());
            return of(null);
          })
        )
      ),
      map((activeCompany) =>
        activeCompany
          ? activeCompanyAction.success({ activeCompany })
          : activeCompanyAction.failed()
      )
    );
  }

  private setDefaultActiveCompany(): Observable<Action> {
    return this.actions$.pipe(
      ofType(activeCompanyAction.init),
      map((activeCompany) =>
        activeCompany
          ? activeCompanyAction.success({ activeCompany })
          : activeCompanyAction.failed()
      )
    );
  }
}
