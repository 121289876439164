import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  catchError,
  delay,
  exhaustMap,
  map,
  Observable,
  of,
  switchMap,
  take,
  takeWhile,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';

import { FeedGroupsService } from '@app/services/fe-api-new/feedGroups.service';
import { ShopsService } from '@app/services/fe-api-new/shops.service';

import {
  cancelShopRun,
  fetchShop,
  getShopInitialStatus,
  getShopStatus,
  selectShopInProgress,
  startStatusPolling,
  stopStatusPolling,
  triggerShopRun,
} from '@store/product-import';

import { AppState } from '../app.state';
import { SnackbarErrorAction } from '../snackbar';

@Injectable()
export class ProductImportEffects {
  constructor(
    private readonly shopService: ShopsService,
    private readonly actions$: Actions,
    private readonly feedGroupsService: FeedGroupsService,
    private readonly store: Store<AppState>
  ) {}

  public readonly getShop$ = createEffect(() => this.getShop());

  public readonly runShop$ = createEffect(() => this.runShop());

  public readonly cancelShopRun$ = createEffect(() => this.cancelShopRun());

  public readonly getShopStatus$ = createEffect(() => this.getShopStatus());

  public readonly getShopStatusAfterRunShop$ = createEffect(() => this.getShopStatusAfterRunShop());

  public readonly handleRunError$ = createEffect(() => this.handleRunError());

  public readonly getShopInitialStatus$ = createEffect(() => this.getShopInitialStatus());

  public readonly handleFetchError$ = createEffect(() => this.handleFetchError());

  public readonly stopPolling$ = createEffect(() => this.stopPolling(), { dispatch: false });

  public readonly startPolling$ = createEffect(() => this.startPolling(), { dispatch: false });

  private isPollingActive = false;

  private runShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.init),
      switchMap((action) =>
        this.feedGroupsService.runShop().pipe(
          map(() => triggerShopRun.success({ shopName: action.shopName })),
          catchError(() => of(triggerShopRun.failed()))
        )
      )
    );
  }

  private handleRunError(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.failed),
      map(() =>
        SnackbarErrorAction({
          message: `Failed to trigger the import, please try again later. If the problem persists, please <a href="mailto:customerservice@omniaretail.com">contact us</a>.`,
          hasHtml: true,
        })
      )
    );
  }

  private getShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchShop.init),
      switchMap(() =>
        this.shopService.getShops().pipe(
          map((shops) => {
            return fetchShop.success({ shop: shops[0] });
          }),
          catchError((error: any) => {
            console.error(error);
            return of(fetchShop.failed());
          })
        )
      )
    );
  }

  private handleFetchError(): Observable<Action> {
    return this.actions$.pipe(
      ofType(fetchShop.failed, getShopStatus.failed()),
      map(() =>
        SnackbarErrorAction({
          message: `An unexpected error occurred, please try again later. If the problem persists, please <a href="mailto:customerservice@omniaretail.com">contact us</a>.`,
          hasHtml: true,
        })
      )
    );
  }

  private getShopInitialStatus(): Observable<Action> {
    return this.actions$.pipe(
      ofType(getShopInitialStatus.init),
      tap(() => (this.isPollingActive = true)),
      exhaustMap(() =>
        this.feedGroupsService.getShopStatus().pipe(
          map((status) => {
            return getShopStatus.success({ shop: { status } });
          }),
          catchError(() => of(getShopStatus.failed()))
        )
      )
    );
  }

  /**
   * After the success of run shop or success of cancel shop,
   * shop status needs to be updated
   * @remark delay of 5sec is added to show cancelling state as in Omnia-portal
   */
  private getShopStatusAfterRunShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.success, cancelShopRun.success),
      switchMap((action) => of(getShopStatus.init({ shopName: action.shopName })))
    );
  }

  /**
   * This effect is used to poll shop status and update the store.
   * Since we need different timeouts for long and short polling, we only take
   * one value from the service call and re-run the effect checking the status of the job to set the short or long polling time.
   */
  private getShopStatus(): Observable<Action> {
    return this.actions$.pipe(
      ofType(getShopStatus.init, getShopStatus.success, triggerShopRun.failed),
      withLatestFrom(this.store.select(selectShopInProgress)),
      takeWhile(() => this.isPollingActive),
      switchMap(([{ _ }, inProgress]) => {
        const updateTime = inProgress ? 5000 : 20000;
        //const shortTimer = 5000;
        return timer(0, updateTime).pipe(
          take(1),
          takeWhile(() => this.isPollingActive),
          delay(updateTime),
          exhaustMap(() =>
            this.feedGroupsService.getShopStatus().pipe(
              map((status) => {
                return getShopStatus.success({ shop: { status } });
              }),
              catchError(() => of(getShopStatus.failed()))
            )
          )
        );
      })
    );
  }

  private cancelShopRun(): Observable<Action> {
    return this.actions$.pipe(
      ofType(cancelShopRun.init),
      switchMap((action) =>
        this.feedGroupsService.cancelShop().pipe(
          map(() => cancelShopRun.success({ shopName: action.shopName })),
          catchError(() => of(cancelShopRun.failed()))
        )
      )
    );
  }

  private stopPolling() {
    return this.actions$.pipe(
      ofType(stopStatusPolling),
      map(() => (this.isPollingActive = false))
    );
  }

  private startPolling() {
    return this.actions$.pipe(
      ofType(startStatusPolling),
      map(() => (this.isPollingActive = true))
    );
  }
}
