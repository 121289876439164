import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private matIconRegistry = inject(MatIconRegistry);

  init() {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
