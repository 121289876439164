import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import * as FontFaceObserver from 'fontfaceobserver';

import { authAction } from '@store/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    location.reload();
  }

  private readonly fontLoadingWaitingTime = 10000;

  constructor(
    private readonly store: Store<AppState>,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {
    this.store.dispatch(authAction.init());
  }

  ngOnInit() {
    this.awaitFontsRendering();
  }

  private awaitFontsRendering(): void {
    // Avoid flash of icon placeholder text during SSR -> client JS handover.
    const materialSymbols = new FontFaceObserver('Material Symbols Outlined').load(
      null,
      this.fontLoadingWaitingTime
    );
    const materialIcons = new FontFaceObserver('Material Icons').load(
      null,
      this.fontLoadingWaitingTime
    );

    Promise.all([materialSymbols, materialIcons]).then(() =>
      this.renderer.addClass(this.elementRef.nativeElement, 'has-loaded-icons')
    );
  }
}
