import { createSelector } from '@ngrx/store';

import { AppState } from '@store/app.state';

import { Shop, ShopStatus } from '@models/interfaces/shops.interface';

import { ProductImportState } from './product-import.reducers';

const selectProductImportFeature = ({ productImport }: AppState) => productImport;

export const selectShop = createSelector(
  selectProductImportFeature,
  (productImportState: ProductImportState) => (productImportState?.shop as Shop) ?? undefined
);

export const selectShopName = createSelector(selectShop, (shop) => shop?.name);

export const selectShopStatus = createSelector(selectShop, (shop: Shop) => {
  return shop?.status;
});

export const selectShopStatusStatus = createSelector(
  selectShopStatus,
  (status: ShopStatus | undefined) => {
    return status?.status;
  }
);

export const selectShopStatusExtraInfo = createSelector(
  selectShopStatus,
  (status: ShopStatus | undefined) => {
    return status?.extrainfo;
  }
);

export const selectShopInProgress = createSelector(
  selectShopStatus,
  (status: ShopStatus | undefined) => status?.inprogress
);

export const selectIsShopSelected = createSelector(
  selectShopStatus,
  (status: ShopStatus | undefined) => Boolean(status?.id)
);

export const selectGroupId = createSelector(
  selectProductImportFeature,
  ({ groupId }: ProductImportState) => (groupId === null ? 'default' : groupId)
);

export const selectGroupDisplayName = createSelector(
  selectProductImportFeature,
  ({ groupDisplayName }: ProductImportState) => groupDisplayName
);
