import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  ENVIRONMENT_INITIALIZER,
  importProvidersFrom,
  inject,
  provideZoneChangeDetection,
} from '@angular/core';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { ApiModule, Configuration } from '@Patagona/pricemonitor-internal-typescript-angular-13';

import { AppRoutes } from '@app/app.routes';
import { resetStateMetaReducer } from '@app/app-meta-reducers';
import { extModules } from '@app/build-specifics';
import { SnackbarService } from '@app/components/snackbar/snackbar/snackbar.service';
import { UserTokenService } from '@app/services/authentication/user-token.service';
import { IconService } from '@app/services/Icon.service';
import { AuthInterceptor } from '@app/services/interceptors/auth.interceptor';
import { BackendTracing } from '@app/services/interceptors/backend-tracing.interceptor';
import { BlockGetWithSidMismatchInterceptor } from '@app/services/interceptors/block-get-sid-mismatch.interceptor';
import { BlockPostPutWithSidMismatchInterceptor } from '@app/services/interceptors/block-post-put-sid-mismatch.interceptor';
import { ErrorInterceptor } from '@app/services/interceptors/error-interceptor.service';
import { InvalidTokenInterceptor } from '@app/services/interceptors/invalid-token-interceptor.service';
import { headerReducer, ScrollElementIntoViewEffects } from '@app/store/legacy';
import { LoadingSpinnerEffects } from '@app/store/loader-spinner/loader-spinner.effects';
import { RouterEffects } from '@app/store/router';
import { SnackbarEffects, snackbarReducer } from '@app/store/snackbar';
import {
  AuthenticationEffects,
  CompaniesEffects,
  ContractsEffects,
  UserDetailsEffects,
  UserPortalsEffects,
  UserPreferencesEffects,
  userReducer,
} from '@app/store/user';
import { LookerEffects } from '@app/store/user/looker/looker.effects';

import { authenticationConfig, authenticationProviders } from './authentication.config';
import { PMConfigFactory } from './pricemonitor-auth.config';
import { providersList } from './providers.config';
import { tracingProviders } from './tracing.config';

export const APP_CONFIG = {
  providers: [
    importProvidersFrom(MatLegacySnackBarModule, ApiModule),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAuth0(authenticationConfig),
    provideRouter(AppRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideStore(
      {
        user: userReducer,
        snackbar: snackbarReducer,
        router: routerReducer,
        header: headerReducer,
      },
      { metaReducers: [resetStateMetaReducer] }
    ),
    provideRouterStore(),
    provideEffects([
      RouterEffects,
      LoadingSpinnerEffects,
      AuthenticationEffects,
      UserDetailsEffects,
      UserPortalsEffects,
      LookerEffects,
      CompaniesEffects,
      ContractsEffects,
      UserPreferencesEffects,
      SnackbarEffects,
      ScrollElementIntoViewEffects,
    ]),
    extModules,
    SnackbarService,
    [...providersList],
    [...authenticationProviders],
    [...tracingProviders],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InvalidTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendTracing,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockGetWithSidMismatchInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlockPostPutWithSidMismatchInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: Configuration,
      useFactory: PMConfigFactory,
      deps: [UserTokenService],
      multi: false,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => inject(IconService).init(),
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
