import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, switchMap, take, throwError } from 'rxjs';

import { HttpMessages } from '@app/models/enum/http-messages.enum';
import { AppState } from '@app/store/app.state';
import { selectUserId } from '@app/store/user';
import { AppInsightsService } from '@app/tracing/application-insights/app-insights.service';
import { getCurrentSid } from '@app/utils/helpers/get-current-sid';

import { environment } from '@environments/environment';

@Injectable()
export class BlockPostPutWithSidMismatchInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store<AppState>,
    private readonly appInsightsService: AppInsightsService
  ) {}

  private checkIfShouldBlockRequest(urlPath: string): boolean {
    const currentSid = getCurrentSid(window.location);
    return !currentSid || urlPath.indexOf(currentSid) < 0;
  }

  private allowedPaths = ['/api/v3/companies', '/api/v3/account'];

  private checkIfShouldAllowRequest(url: string, method: string) {
    const { pricemonitorApiBaseUrl } = environment;
    const writingMethods = ['POST', 'PUT'];
    const isRequestToPricemonitorApi = url.indexOf(pricemonitorApiBaseUrl) > -1;
    const isPostOrPutRequest = writingMethods.includes(method);
    const allowedPath = this.allowedPaths.find((path) => url.indexOf(path) > -1);
    return !isRequestToPricemonitorApi || !isPostOrPutRequest || allowedPath;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method } = request;
    const isAllowed = this.checkIfShouldAllowRequest(url, method);
    if (isAllowed) {
      return next.handle(request);
    }
    return this.store.select(selectUserId).pipe(
      take(1),
      switchMap((userId) => {
        const shouldBlock = this.checkIfShouldBlockRequest(url);
        if (shouldBlock) {
          const traceFields = {
            userId,
            url,
            method,
          };
          this.appInsightsService.logEvent(`SID mismatch in ${method} request`, traceFields);
          return throwError(() => ({
            error: `SID mismatch in ${method} request`,
            status: 403,
            statusText: HttpMessages.Forbidden,
            ...traceFields,
          }));
        }
        return next.handle(request);
      })
    );
  }
}
