import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { APP_CONFIG } from '@app/config/app.config';

import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

sessionStorage.setItem('TabSessionId', window.crypto.randomUUID());

bootstrapApplication(AppComponent, APP_CONFIG).catch((err) => console.error(err));
