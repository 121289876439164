import { createAction, props } from '@ngrx/store';

import { ShopStatusOptions } from '@app/models/enum/shops.enum';

import { ApiActions } from '@store/api-actions';

import { Shop } from '@models/interfaces/shops.interface';

export enum ProductImportActions {
  // Feed Groups actions
  SET_GROUP_ID = '[feed-groups] Set group id',
  SET_GROUP_DISPLAY_NAME = '[feed-groups] Set goupr display name',
}

/**
 * Feed Groups actions
 */
export const setGroupId = createAction(
  ProductImportActions.SET_GROUP_ID,
  props<{ groupId: string }>()
);
export const setGroupDisplayName = createAction(
  ProductImportActions.SET_GROUP_DISPLAY_NAME,
  props<{ groupDisplayName: string }>()
);

/**
 * Shop actions
 */
export const loadShopsAndStatus = new ApiActions<{}, { shops: Shop[] }>(
  '[Product Import] Load shops '
);

export const fetchShop = new ApiActions<{}, { shops: Shop }>('[Product Import] Fetch shop');

export const triggerShopRun = new ApiActions<{ shopName: string }, { shopName: string }>(
  '[Product Import] Run shop '
);

export const cancelShopRun = new ApiActions<{ shopName: string }, { shopName: string }>(
  '[Product Import] Cancel shop-run '
);

export const getShopStatus = new ApiActions<{ shopName: string }, { shop: Shop }>(
  '[Product Import] Get shop status '
);

export const getShopInitialStatus = new ApiActions<{ shopName: string }, { shop: Shop }>(
  '[Product Import] Get shop initial status'
);

export const stopStatusPolling = createAction('[Product Import] Stop status polling');

export const startStatusPolling = createAction('[Product Import] Start status polling');

export const setShopStatus = createAction(
  '[Product Import] Set shop status',
  props<{ status: ShopStatusOptions }>()
);
