import { createReducer, on } from '@ngrx/store';

import { ShopStatusOptions } from '@app/models/enum/shops.enum';

import {
  cancelShopRun,
  fetchShop,
  getShopStatus,
  loadShopsAndStatus,
  setGroupDisplayName,
  setGroupId,
  setShopStatus,
  triggerShopRun,
} from '@store/product-import';

import { Shop } from '@models/interfaces/shops.interface';

export const productImportFeatureKey = 'productImport';

export interface ProductImportState {
  shop: Shop | undefined;
  groupId?: string;
  groupDisplayName?: string;
}

export const initialProductImportState: ProductImportState = {
  shop: undefined,
  groupId: undefined,
  groupDisplayName: undefined,
};

export const ERROR_SHOP_NAME = 'ERROR';

export const productImportReducer = createReducer(
  initialProductImportState,
  on(loadShopsAndStatus.success, (state, { shop }) => {
    return { ...(state || initialProductImportState), shop };
  }),
  on(fetchShop.success, (state, { shop }) => {
    return { ...(state || initialProductImportState), shop };
  }),
  on(fetchShop.failed, (state, _) => {
    return {
      ...(state || initialProductImportState),
      shop: {
        ...state.shop!,
        name: ERROR_SHOP_NAME,
      },
    };
  }),
  on(getShopStatus.success, (state, { shop }) => {
    return {
      ...(state || initialProductImportState),
      shop: {
        ...state.shop!,
        ...shop,
        status: {
          ...state.shop?.status!,
          ...shop.status,
        },
      },
    };
  }),
  on(triggerShopRun.init, (state, _) => ({
    ...(state || initialProductImportState),
    shop: {
      ...state.shop!,
      status: {
        ...state.shop!.status!,
        message: '',
        status: ShopStatusOptions.PROCESSING,
        inprogress: true,
      },
    },
  })),
  on(cancelShopRun.init, (state, _) => ({
    ...(state || initialProductImportState),
    shop: {
      ...state.shop!,
      status: {
        ...state.shop!.status!,
        message: '',
        status: ShopStatusOptions.CANCELING,
        inprogress: true,
      },
    },
  })),
  on(triggerShopRun.failed, (state, _) => ({
    ...(state || initialProductImportState),
    shop: {
      ...state.shop!,
      status: {
        ...state.shop!.status!,
        message: '',
        status: ShopStatusOptions.ERROR,
        inprogress: false,
      },
    },
  })),
  on(setGroupId, (state, { groupId }) => ({
    ...(state || initialProductImportState),
    groupId,
  })),
  on(setGroupDisplayName, (state, { groupDisplayName }) => ({
    ...(state || initialProductImportState),
    groupDisplayName,
  })),
  on(setShopStatus, (state, { status }) => ({
    ...(state || initialProductImportState),
    shop: {
      ...state.shop!,
      status: {
        ...state.shop!.status!,
        status,
      },
    },
  }))
);
